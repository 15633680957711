import * as React from "react"
import { Helmet } from "react-helmet"

import { Header, Footer, BottomNavbar, Workout } from "../components/index"

import SubTitle from "../components/Common/SubTitle/SubTitle"
import Container from "../components/Common/Container/Container"

const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>Esteekey - Workout</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        <div className="mt-6 xl:mt-24 mb-12 mx-4 sm:mx-0 ">
          <Container>
            <SubTitle
              title1="Daily"
              title2="Workout"
              text="Your daily workout is here"
            />
          </Container>
        </div>
        <Workout />
      </div>
      <BottomNavbar />
      <Footer />
    </div>
  )
}

export default IndexPage
